export let localCache = {
    set (key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    get (key) {
        var val = localStorage.getItem(key);
        var dataobj = JSON.parse(val);
        return dataobj;
    },
    remove (key) {
        return localStorage.removeItem(key);
    },
};