import {queryCombo} from '../api'
export default {
    namespaced:true,
    actions: {
        // 获取用户套餐信息
        async getCombo({commit,state}) {
            const result = await queryCombo();
            if (result.code === 1) {
                console.log(result.data)
                const combo = result.data;
                const sevenBill = result.data.sevenBill;
                commit("RECEIVE_RECHARGE",{combo,sevenBill});
            }
        },
    },
    mutations: {
        RECEIVE_RECHARGE: (state,{combo,sevenBill}) => {
            state.combo = combo;
            state.sevenBill = sevenBill;
        },
    },
    state: {
        combo: {}, // 套餐内容
        sevenBill: [], // 近7天流水
    },
    getters: {

    }
}
