import {getUserPackage} from '../api'
export default {
    namespaced:true,
    actions: {
        // 获取用户套餐信息
        async getUserPackage({commit,state}) {
            const result = await getUserPackage();
            if (result.code === 1) {
                console.log(result.data)
                const userPackage = result.data;
                // console.log(baseList);
                commit("RECEIVE_USER_PACKAGE",{userPackage});
            }
        },

        // 同步记录用户信息
        /*recordUser ({commit}){
            commit("RECEIVE_USER_INFO,",{userInfo});
        }*/
    },
    mutations: {
        RECEIVE_USER_PACKAGE: (state,{userPackage}) => {
            state.userPackage = userPackage;
        },

        /*RECEIVE_USER_INFO: (state,{userInfo}) => {
            state.userInfo = userInfo;
        }*/
    },
    state: {
        userPackage: {}, // 用户套餐
    },
    getters: {

    }
}
