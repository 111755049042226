<template>
    <section class="my-order">
        <HeaderTop title="我的返佣">
            <router-link class="header_search" slot="left" :to="navName?'/msite':'/profile'">
                <i class="iconfont icon-fanhui"></i>
            </router-link>
        </HeaderTop>
        <section class="my-order-content">
            <section class="order" v-for="(rebate,index) in rebates" :key="rebate.id">
                <p>
                    <span class="num">订单编号：{{rebate.orderNo}}</span>
                    <button class="waitBtn" v-if="rebate.statusName=='待返佣'">{{rebate.statusName}}</button>
                    <button class="alreadyBtn" v-else>{{rebate.statusName}}</button>
                </p>
                <p>
                    <span>订单金额：{{rebate.orderPrice}}元</span>
                    <span>订单佣金：{{rebate.rebatePrice}}元</span>
                </p>
                <p>
                    <span>订单支付手机：</span>
                    <span>{{rebate.payPhone}}</span>
                </p>
                <p>
                    <span>订单支付时间：</span>
                    <span>{{rebate.payDate}}</span>
                </p>
            </section>
        </section>
    </section>
</template>

<script>
import HeaderTop from "@/components/HeaderTop/HeaderTop";
import {queryRebate} from "@/api";
export default {
    name: "MyRebate",
    data(){
        return{
            navName: "", // 携带导航名(以便区分返回到首页还是个人中心页)
            currentPage: 1, // 查询页数
            rowCount: 10, // 每页行数
            rebates: [], //
        }
    },
    components: {
        HeaderTop
    },
    mounted() {
        this.navName = this.$route.query.name;
        this.getRebate();
    },
    methods: {
        async getRebate(){
            let result = await queryRebate(this.currentPage,this.rowCount);
            if(result.code==1){
                this.rebates = result.data.data;
                console.log(this.rebates)
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../common/stylus/mixins.less";
.my-order {
    width: 100%;
    .header {
        .header_search {
            .iconfont {
                color: #fff;
            }
        }
    }
    .my-order-content {
        .clearFix();
        margin-top: 45px;
        .order {
            margin: 10px auto 0;
            width: 96%;
            padding: 15px 10px;
            background: #fff;
            box-sizing: border-box;
            color: #333;
            border-radius: 5px;
            p {
                font-size: 15px;
                color: #333;
                line-height: 30px;
                display: flex;
                justify-content: space-between;
                span {
                    &.num {
                        font-weight: bold;
                    }
                }
                button {
                    background: transparent;
                    font-size: 13px;
                    outline: none;
                    border-radius: 5px;
                    padding: 5px 10px;
                    &.waitBtn {
                        border: 1px solid #ff4d4f;
                        color: #ff4d4f;
                    }
                    &.alreadyBtn {
                        border: 1px solid #02a774;
                        color: #02a774;
                    }
                }
            }
        }
    }

}
</style>