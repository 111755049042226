<template>
    <section class="contact">
        <HeaderTop title="联系我们">
            <router-link class="header_search" slot="left" :to="navName?'/msite':'/profile'">
                <i class="iconfont icon-fanhui"></i>
            </router-link>
            <router-link class="header_login" slot="right" to="/addcontact">
                <span class="header_login_text">新建工单</span>
            </router-link>
        </HeaderTop>
        <section class="contact-content">
            <section class="work-order" v-for="(workOrder,index) in workOrders" :key="workOrder.id">
                <p>
                    <span class="data">{{workOrder.title}}</span>
                    <span class="state">服务中</span>
                </p>
                <p>
                    <span>创建时间：{{workOrder.createDate}}</span>
                </p>
                <p>
                    <span>最后回复：{{workOrder.replyDate}}</span>
                    <button @click="toDetail(workOrder)">详情</button>
                </p>
            </section>
        </section>
    </section>
</template>

<script>
import HeaderTop from "@/components/HeaderTop/HeaderTop";
import {queryContact} from "@/api";
export default {
    name: "ContactUs",
    data(){
        return {
            navName: "", // 携带导航名(以便区分返回到首页还是个人中心页)
            currentPage: 1, // 查询页数
            rowCount: 10, // 每页行数
            workOrders: [], // 工单
        }
    },
    components: {
        HeaderTop
    },
    mounted() {
        this.navName = this.$route.query.name;
        this.getContact();
    },
    methods: {
        // 查询联系我们
        async getContact(){
            let result = await queryContact(this.currentPage,this.rowCount);
            if(result.code==1){
                this.workOrders = result.data.data;
                console.log(this.workOrders);
            }
        },
        // 去详情
        toDetail(detail){
            let obj = JSON.stringify(detail);
            this.$router.push(`/contactdetail?detail=${obj}`)
        },
    }
}
</script>

<style lang="less" scoped>
@import "../../common/stylus/mixins.less";
.contact {
    width: 100%;
    .header {
        .header_search {
            .iconfont {
                color: #fff;
            }
        }
    }
    .contact-content {
        .clearFix();
        margin-top: 45px;
        .work-order {
            margin: 10px auto 0;
            width: 96%;
            padding: 15px 10px;
            background: #fff;
            box-sizing: border-box;
            color: #333;
            border-radius: 5px;
            p {
                font-size: 15px;
                color: #333;
                line-height: 30px;
                display: flex;
                justify-content: space-between;
                span {
                    &.data {
                        font-weight: bold;
                    }
                    &.state {
                        font-weight: bold;
                    }
                }
                button {
                    background: transparent;
                    border: 1px solid #02a774;
                    font-size: 13px;
                    color: #02a774;
                    outline: none;
                    border-radius: 5px;
                    padding: 5px 10px;
                    margin-left: 20px;
                }
            }
        }
    }
}
</style>