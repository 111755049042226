/*
包含n个接口请求函数的模块
函数的返回值: promise对象
 */
// 引入axios（axios进行二次封装）
import request from '@/utils/request.js';
// const BASE_URL = 'http://150.158.31.28:7700'
const BASE_URL = '/dev-api';

/*export const getCodeImg = () => {
    return ("http://150.158.31.28:7700/center/chat/getPhoneVerify");
}*/
// 登录：获取图片验证码
export const getCodeImg = () => {
    return (BASE_URL + "/center/getVerifyCode");
}

// 登录：点击登录按钮
export const reqPwdLogin = (phone, password, captcha,platformCode) => request(BASE_URL + '/center/login', {
    userName: phone,
    password: password,
    verifyCode: captcha,
    platformCode: platformCode,
}, 'post')

// 个人中心：通过登陆用户查询Chat用户套餐
export const getUserPackage = () => request(
    BASE_URL + '/kimhae/comm/combo/getChatCombo'
)

// 忘记密码：获取手机验证码图片
export const getPhoneImg = () => {
    return (BASE_URL + "/center/chat/getPhoneVerify");
}

// 忘记密码：发送手机验证码
export const sendSmsCode = (phone,captcha) => request(
    BASE_URL+'/center/chat/sendPhone',
    {
        phone,
        verifyCode: captcha
    }
)

// 忘记密码：重置密码(password是后端规定字段，pwd1是我传参时候字段)
/*export const resetPwd = (phone,pwd1,smsCode) => request(
    BASE_URL + '/center/chat/forgot',
    {
        phone,
        password: pwd1,
        captcha: smsCode
    },
    'post'
)*/
// 忘记密码：点击重置密码按钮（formData）
export const resetPwd = (data) => request(
    BASE_URL + '/center/chat/forgot',
    data,
    'post'
)

// 用户注册：
export const userRegister = (data) => request(
    BASE_URL + '/center/chat/register',
    data,
    'post'
)

// 农技问答：校验是否新用户
export const checkNewUser = () => request(
    BASE_URL+'/kimhae/chat/to/check',
)

// 农技问答：聊天室
export const chatting = (quiz) => request(
    BASE_URL+'/kimhae/chat/to/chatting',
    {
        quiz
    }
)

// 农技问答：查询小于主键ID的用户聊天记录
export const queryChatRecord = (currentPage,rowCount,id) => request(
    BASE_URL+'/kimhae/chat/record/query',
    {
        currentPage,
        rowCount,
        id
    }
)

// 点数充值
export const queryPayment = () => request(
    BASE_URL+'/kimhae/comm/payment/queryChat',
)

// 点数充值：购买：新增微信订单, 超过30分钟自动关闭订单
export const addPay = (data) => request(
    BASE_URL + '/kimhae/comm/order/wxNativePay',
    data,
    'post'
)

// 我的套餐：通过登陆用户查询Chat用户套餐
export const queryCombo = () => request(
    BASE_URL+'/kimhae/comm/combo/getChatCombo',
)

// 个人中心：余额提现：新增Chat提现
export const addCashOut = (data) => request(
    BASE_URL + '/kimhae/comm/withdraw/addChat',
    data,
    'post'
)

// 余额提现：提现记录
export const queryWithdraw = (currentPage,rowCount) => request(
    BASE_URL+'/kimhae/comm/withdraw/queryChat',
    {
        currentPage,
        rowCount
    }
)

// 个人中心：我的订单：查询Chat公用订单
export const queryOrder = (currentPage,rowCount) => request(
    BASE_URL+'/kimhae/comm/order/queryChat',
    {
        currentPage,
        rowCount
    }
)

// 个人中心：我的订单：去支付
export const goPay = (orderNo) => request(
    BASE_URL+'/kimhae/comm/order/goPay',
    {
        orderNo
    }
)

// 个人中心：我的订单：新增微信订单, 超过30分钟自动关闭订单
export const addWxPayOrder = (data) => request(
    BASE_URL + '/kimhae/comm/order/wxPay',
    data,
    'post'
)

// 个人中心：我的返佣：查询Chat返佣功能
export const queryRebate = (currentPage,rowCount) => request(
    BASE_URL+'/kimhae/comm/rebate/queryChat',
    {
        currentPage,
        rowCount
    }
)

// 个人中心：修改资料：获取修改资料验证图片
export const updateVerify = () => {
    return (BASE_URL+'/center/chat/getUpdateVerify');
}

// 个人中心：修改资料：用户修改资料
export const updateUser = (data) => request(
    BASE_URL + '/center/sys/user/userUpdate',
    data,
    'post'
)

// 个人中心：联系我们：查询Chat联系我们
export const queryContact = (currentPage,rowCount) => request(
    BASE_URL+'/kimhae/comm/contact/queryChat',
    {
        currentPage,
        rowCount
    }
)

// 个人中心：联系我们：新增Chat联系我们
export const addContact = (data) => request(
    BASE_URL + '/kimhae/comm/contact/addChat',
    data,
    'post'
)

