/*
ajax请求函数模块
返回值: promise对象(异步返回的数据是: response.data)
 */
import axios from 'axios';
import {localCache} from "@/utils/utils";
import {Indicator} from "mint-ui";

// 在 request 拦截器中
axios.interceptors.request.use(config => {
    Indicator.open({
        text: '加载中',
        spinnerType: 'fading-circle'
    });

    let token = localCache.get("token");
    // console.log(token);
    let headers = {};
    if (config && config.headers) {
         headers = config.headers;
         config.headers['Authorization'] = token
        // console.log(headers)
    } else {
        // application/x-www-form-urlencoded
        // multipart/form-data
        headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": token,
        };
    }
    config.headers = {
        // Authorization,
        // "TENANT-ID": process.env.VUE_APP_TENANTID,
        ...headers,
    };

    //headers中的content-type 默认的大多数情况是  application/json，就是json序列化的格式
    /*config.headers['Authorization'] = token
    config.headers['Content-Type'] = 'application/json'
    //为了判断是否为formdata格式，增加了一个变量为type,如果type存在，而且是form的话，则代表是formData的格式
    if (config.type && config.type === 'form') {
        config.headers['Content-Type'] = 'multipart/form-data'

    }*/

    return config
})
// 在 response 拦截器中
axios.interceptors.response.use(config => {
    Indicator.close();
    return config;
})

export default function request (url, data={}, type='GET') {
    return new Promise(function (resolve, reject) {
        // 执行异步ajax请求
        let promise
        if (type === 'GET') {
            // 准备url query参数数据
            let dataStr = '' //数据拼接字符串
            Object.keys(data).forEach(key => {
                dataStr += key + '=' + data[key] + '&'
            })
            if (dataStr !== '') {
                dataStr = dataStr.substring(0, dataStr.lastIndexOf('&'))
                url = url + '?' + dataStr
            }
            // 发送get请求
            promise = axios.get(url)
        } else {
            // 发送post请求
            promise = axios.post(url, data)
        }
        promise.then(function (response) {
            // 成功了调用resolve()
            resolve(response.data)
        }).catch(function (error) {
            //失败了调用reject()
            reject(error)
        })
    })
}
