<template>
    <section class="loginContainer">
        <div class="loginInner">
            <div class="login_header">
                <h2 class="login_logo">农技百科</h2>
            </div>
            <div class="login_content">
                <form @submit.prevent="forget">
                    <div>
                        <section>
                            <section class="login_message">
                                <input type="tel" placeholder="请输入手机号码" autoComplete="off" v-model="phone" @blur="getCaptcha" @input="limitLength">
                            </section>
                            <input type="text" value="" style=" opacity: 0; width: 0;height: 0; " />
                            <section class="login_verification" style="margin-top: 0">
                                <input type="text" minlength="6" maxlength="20" placeholder="请输入新的密码" autoComplete="new-password" v-if="showPwd1" v-model="pwd1">
                                <input type="password" minlength="6" maxlength="20" placeholder="请输入新的密码" autoComplete="new-password" v-else v-model="pwd1">
                                <div class="switch_button" :class="showPwd1?'on':'off'" @click="showPwd1=!showPwd1">
                                    <div class="switch_circle" :class="{right: showPwd1}"></div>
                                    <span class="switch_text">{{showPwd1 ? 'abc' : '...'}}</span>
                                </div>
                            </section>
                            <section class="login_verification">
                                <input type="text" minlength="6" maxlength="20" placeholder="再次输入新密码" autoComplete="new-password" v-if="showPwd2" v-model="pwd2">
                                <input type="password" minlength="6" maxlength="20" placeholder="再次输入新密码" autoComplete="new-password" v-else v-model="pwd2">
                                <div class="switch_button" :class="showPwd2?'on':'off'" @click="showPwd2=!showPwd2">
                                    <div class="switch_circle" :class="{right: showPwd2}"></div>
                                    <span class="switch_text">{{showPwd2 ? 'abc' : '...'}}</span>
                                </div>
                            </section>
                            <section class="login_message">
                                <input type="text" maxlength="4" placeholder="请输入图片验证码" v-model="captcha">
                                <span class="get_verification" v-if="!codeUrl" @click="getCaptcha">获取图片验证码</span>
                                <img v-else class="get_verification" :src="codeUrl" alt="captcha" @click="getCaptcha" ref="captcha"/>
                            </section>
                            <section class="login_message">
                                <input type="text" maxlength="6" placeholder="请输入手机验证码" v-model="smsCode">
                                <!--:disabled="!rightPhone"  计时大于0，显示已发送多少s,计时<=0,表示不需要计时，则显示获取验证码-->
                                <button class="get_verification" :class="{right_phone:rightPhone}"
                                        @click.prevent="getSmsCode">{{computeTime>0 ?`已发送(${computeTime})s` : "获取验证码" }}</button>
                            </section>
                        </section>
                    </div>
                    <button class="login_submit">重置密码</button>
                </form>
            </div>
            <a href="javascript:" class="go_back" @click="$router.back()">
                <i class="iconfont icon-fanhui"></i>
            </a>
        </div>
        <AlertTip :alertText="alertText" v-show="alertShow" @closeTip="closeTip"></AlertTip>
    </section>
</template>

<script>
import AlertTip from "@/components/AlertTip/AlertTip";
import {getPhoneImg, resetPwd, sendSmsCode} from "@/api";
import { Toast } from 'mint-ui';
export default {
    name: "ForgetPwd",
    data () {
        return {
            computeTime: 0, // 计时
            phone: '', // 手机号
            pwd1: '', // 新密码
            pwd2: '', // 确认新密码
            showPwd1: false, // 是否显示密码
            showPwd2: false, // 是否显示密码
            captcha: '', // 图形验证码
            codeUrl: "", // 图形验证码url
            platformCode: 1191,
            smsCode:'', // 短信验证码
            alertText: "", // 提示文本
            alertShow: false, // 是否显示警告框
        }
    },
    components: {
        AlertTip
    },
    computed: {
        rightPhone () {
            return /1[3456789]\d{9}/.test(this.phone);
        }
    },
    methods: {
        showAlert(alertText){
            this.alertShow = true;
            this.alertText = alertText;
        },
        // 关闭警告框
        closeTip () {
            this.alertShow = false;
            this.alertText = "";
        },
        // 限制输入长度
        limitLength() {
            if (this.phone.length > 11) {
                this.phone = this.phone.slice(0, 11)
            }
        },
        // 获取图片验证码
        getCaptcha () {
            const {phone} = this;
            if(!phone){
                this.showAlert("请输入手机号");
                return;
            }else if(!this.rightPhone){
                this.showAlert("请输入合法的手机号");
                return;
            }
            this.codeUrl = `${getPhoneImg()}?phone=${phone}&num=${Math.random()}`;
        },
        // 获取手机验证码
        async getSmsCode () {
            const {phone,captcha} = this;
            if(!phone){
                this.showAlert("请输入手机号");
                return;
            }else if(!this.rightPhone){
                this.showAlert("请输入合法的手机号");
                return;
            }else if(!captcha){
                this.showAlert("请输入图片验证码");
                return;
            }
            // 如果当前没有计时(避免正在倒计时重新发送验证码出现的问题)
            if(!this.computeTime){
                // 启动倒计时
                this.computeTime = 120;
                this.intervalId = setInterval(() => {
                    this.computeTime--;
                    if(this.computeTime<=0){
                        // 停止计时
                        clearInterval(this.intervalId);
                    }
                },1000)
            }
            // 发送ajax请求(向指定手机号发送验证码短信)
            const result = await sendSmsCode(phone,captcha); // 两种结果，成功或失败

            /*if(result.code==1){
                this.smsCode = "666666";
            }*/
            // 如果失败
            if(result.code!=1){
                // 显示提示
                this.showAlert(result.msg);
                // 停止倒计时
                if(this.computeTime){
                    this.computeTime = 0;
                    clearInterval(this.intervalId);
                    this.intervalId = undefined;
                }
            }
        },
        // 重置密码
        async forget() {
            const {phone, pwd1, pwd2,captcha,smsCode} = this;
            if(!phone){
                // 用户名必须指定
                this.showAlert("请输入手机号");
                return;
            }else if(!this.rightPhone){
                this.showAlert("请输入合法的手机号");
                return;
            }else if(!pwd1){
                // 密码必须指定
                this.showAlert("请输入新的密码");
                return;
            }else if(!pwd2){
                // 密码必须指定
                this.showAlert("再次输入新密码");
                return;
            }else if(pwd1!=pwd2){
                // 密码必须指定
                this.showAlert("输入的密码不一致！");
                return;
            }else if(!captcha){
                // 验证码必须指定
                this.showAlert("请输入图片验证码");
                return;
            }else if(!smsCode){
                // 验证码必须指定
                this.showAlert("请输入手机验证码");
                return;
            }

            const formData = new FormData();
            formData.append('phone', phone);
            formData.append('password', pwd1);
            formData.append('captcha', smsCode);

            let result = await resetPwd(formData);
            // let result = await resetPwd(phone,pwd1,smsCode);
            if(result.code==1){
                Toast('重置密码成功');
                this.$router.replace("/login")
            }else {
                this.showAlert(result.msg);
            }
        },
    }
}
</script>

<style lang="less" scoped>
.loginContainer {
    width: 100%;
    height: 100%;
    background: #fff;
    .loginInner {
        padding-top: 60px;
        width: 80%;
        margin: 0 auto;
        .login_header {
            .login_logo {
                font-size: 40px;
                font-weight: bold;
                color: #02a774;
                text-align: center;
            }
        }
        .login_content {
            margin-top: 40px;
            >form {
                >div {
                    input {
                        width: 100%;
                        height: 100%;
                        padding-left: 10px;
                        box-sizing: border-box;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        outline: 0;
                        font: 400 14px Arial;
                        &:focus {
                            border: 1px solid #02a774;
                        }
                    }
                    .login_message {
                        position: relative;
                        margin-top: 16px;
                        height: 48px;
                        font-size: 14px;
                        background: #fff;
                        .get_verification {
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);
                            color: #666;
                            font-size: 14px;
                            border: 0;
                            background: transparent;
                            &.right_phone {
                                color: #666;
                            }
                        }
                    }
                    .login_verification {
                        position: relative;
                        margin-top: 16px;
                        height: 48px;
                        font-size: 14px;
                        background: #fff;
                        .switch_button {
                            width: 30px;
                            height: 16px;
                            line-height: 16px;
                            border: 1px solid #ddd;
                            border-radius: 8px;

                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);

                            font-size: 12px;
                            color: #fff;
                            transition: background-color .3s,border-color .3s;
                            padding: 0 6px;

                            &.on {
                                background: #02a774;
                            }
                            &.off {
                                background: #fff;
                                .switch_text {
                                    float: right;
                                    color: #ddd;
                                }
                            }

                            .switch_circle {
                                position: absolute;
                                top: -1px;
                                left: -1px;
                                width: 16px;
                                height: 16px;
                                border: 1px solid #ddd;
                                border-radius: 50%;
                                background: #fff;
                                box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
                                transition: transform .3s;
                                &.right {
                                    transform: translateX(30px);
                                }
                            }
                        }
                    }
                }
                .foget_pwd {
                    display: block;
                    font-size: 14px;
                    margin-top: 10px;
                    text-align: right;
                    color: #999;
                }
                .login_submit {
                    display: block;
                    width: 100%;
                    height: 42px;
                    background: #02a774;
                    border-radius: 4px;
                    margin-top: 30px;
                    font-size: 16px;
                    line-height: 42px;
                    text-align: center;
                    color: #fff;
                    border: 0;
                }
            }
            .register {
                width: 100%;
                display: inline-block;
                text-align: center;
                margin-top: 15px;
                font-size: 14px;
                color: #999;
                span:nth-child(2) {
                    color: #02a774;
                }
            }
        }
        .go_back {
            position: absolute;
            top: 5px;
            left: 5px;
            width: 30px;
            height: 30px;
            >.iconfont {
                font-size: 20px;
                color: #999;
            }

        }
    }
}
</style>