<template>
    <section class="add-contact">
        <HeaderTop title="新建工单">
            <router-link class="header_search" slot="left" to="/contactus">
                <i class="iconfont icon-fanhui"></i>
            </router-link>
        </HeaderTop>
        <div class="add-content">
            <section class="add-section">
                <h4 class="title">工单主题</h4>
                <input class="add-txt" v-model="title" />
            </section>
            <section class="add-section">
                <h4 class="title">工单内容</h4>
                <textarea class="add-txt add-textarea" v-model="content"></textarea>
            </section>
            <button class="add-btn" @click="confirm">确认</button>
        </div>
    </section>
</template>

<script>
import HeaderTop from "@/components/HeaderTop/HeaderTop";
import {addContact} from "@/api";
import {Toast} from "mint-ui";
export default {
    name: "AddContact",
    data(){
        return {
            title: "", // 工单主题
            content: "", // 工单内容
        }
    },
    components: {
        HeaderTop
    },
    methods: {
        async confirm(){

            const formData = new FormData();
            formData.append('title', this.title);
            formData.append('content',this.content);

            let result = await addContact(formData);
            if(result.code==1){
                Toast('新建工单成功');
                this.$router.push("/contactus");
            }
        }
    },
}
</script>

<style lang="less" scoped>
@import "../../common/stylus/mixins.less";
.add-contact {
    width: 100%;
    overflow: hidden;
    .header {
        .header_search {
            .iconfont {
                color: #fff;
            }
        }
    }
    .add-content {
        .clearFix();
        margin-top: 45px;
        .add-section {
            .title {
                padding: 18px 15px;
                font-size: 16px;
            }
            .add-txt {
                width: 100%;
                padding: 10px;
                box-sizing: border-box;
                height: 45px;
                background: #fff;
                border: 1px solid #f5f5f5;

                resize: none;/* 取消默认的右下角的两道斜线 */
                outline: none;/* 取消默认聚焦的蓝色线框 */
            }
            .add-textarea {
                height: 120px;
            }
        }
        .add-btn {
            display: block;
            width: 90%;
            margin: 30px auto;
            height: 42px;
            line-height: 42px;
            background: #02a774;
            border-radius: 4px;
            font-size: 16px;
            text-align: center;
            color: #fff;
            border: 0;
        }
    }
}
</style>