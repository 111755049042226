<template>
    <section class="cash">
        <HeaderTop title="余额提现">
            <router-link class="header_search" slot="left" :to="navName?'/msite':'/profile'">
                <i class="iconfont icon-fanhui"></i>
            </router-link>
            <router-link class="header_login" slot="right" to="/mywithdraw">
                <span class="header_login_text">提现记录</span>
            </router-link>
        </HeaderTop>
        <section class="cash-content">
            <section class="cash-out">
                <div class="cash-amount">
                    <span>可提现余额(元)</span>
                    <span>{{combo.balance}}</span>
                </div>
                <div class="cash-amount">
                    <span>已冻结金额(元)</span>
                    <span>{{combo.freeze}}</span>
                </div>
                <div class="cash-info">
                    <form @submit.prevent="cashOut">
                        <div>
                            <section>
                                <section class="cash_message">
                                    <input type="text" maxlength="11" placeholder="用户真实姓名" v-model="name">
                                </section>
                                <section class="cash_message">
                                    <input type="text" placeholder="支付宝账号" v-model="account">
                                </section>
                                <section class="cash_message">
                                    <input type="tel" maxlength="11" placeholder="支付宝绑定手机号" v-model="phone">
                                </section>
                                <section class="cash_message">
                                    <input type="text" maxlength="4" placeholder="请输入图片验证码" v-model="captcha">
                                    <span class="get_verification" v-if="!codeUrl" @click="getCaptcha">获取图片验证码</span>
                                    <img v-else class="get_verification" :src="codeUrl" alt="captcha" @click="getCaptcha" ref="captcha"/>
                                </section>
                                <section class="cash_message">
                                    <input type="text" maxlength="6" placeholder="请输入手机验证码" v-model="smsCode">
                                    <!-- 计时大于0，显示已发送多少s,计时<=0,表示不需要计时，则显示获取验证码 -->
                                    <button class="get_verification" :class="{right_phone:captcha}"
                                            @click.prevent="getSmsCode" >{{computeTime>0 ?`已发送(${computeTime})s` : "获取验证码" }}</button>
                                </section>
                                <section class="cash_option">
                                    <a href="javascript:" class="cash-type" :class="{'link': isChoose[0]}" @click="selectNum(0,5)">
                                        <span>5元</span>
                                    </a>
                                    <a href="javascript:" class="cash-type" :class="{'link': isChoose[1]}" @click="selectNum(1,50)">
                                        <span>50元</span>
                                    </a>
                                    <a href="javascript:" class="cash-type" :class="{'link': isChoose[2]}" @click="selectNum(2,100)">
                                        <span>100元</span>
                                    </a>
                                    <a href="javascript:" class="cash-type" :class="{'link': isChoose[3]}" @click="selectNum(3,200)">
                                        <span>200元</span>
                                    </a>
                                    <a href="javascript:" class="cash-type" :class="{'link': isChoose[4]}" @click="selectNum(4,500)">
                                        <span>500元</span>
                                    </a>
                                    <a href="javascript:" class="cash-type" :class="{'link': isChoose[5]}" @click="selectNum(5,1000)">
                                        <span>1000元</span>
                                    </a>
                                </section>
                            </section>
                        </div>
                        <button class="cash-btn">提现</button>
                    </form>
                </div>
            </section>
        </section>
        <AlertTip :alertText="alertText" v-show="alertShow" @closeTip="closeTip"></AlertTip>
    </section>
</template>

<script>
import HeaderTop from "@/components/HeaderTop/HeaderTop";
import AlertTip from "@/components/AlertTip/AlertTip";
import {mapActions, mapState} from "vuex";
import {addCashOut, getPhoneImg, sendSmsCode} from "@/api";
import { Toast } from 'mint-ui';
export default {
    name: "Cash",
    components: {
        HeaderTop,
        AlertTip
    },
    data () {
        return {
            navName: "", // 携带导航名(以便区分返回到首页还是个人中心页)
            name: "", // 用户真实姓名
            account: "", // 提现账号
            amount: "", // 提现金额
            phone: '', // 支付宝绑定手机号
            captcha: '', // 图形验证码
            codeUrl: "", // 图形验证码url
            smsCode: "", // 手机验证码
            computeTime: 0, // 计时
            alertText: "", // 提示文本
            alertShow: false, // 是否显示警告框
            isChoose: [false,false,false,false,false,false],
        }
    },
    computed: {
        ...mapState("comboAbout",["combo","sevenBill"]),

        rightPhone () {
            return /1[3456789]\d{9}/.test(this.phone);
        }
    },
    mounted() {
        this.navName = this.$route.query.name;
        this.getCombo();
    },
    methods: {
        // 重新派发请求：如果用户没有点击"我的套餐"，数据会拿不到，所以需要重新发请求
        ...mapActions("comboAbout",["getCombo"]),

        showAlert(alertText){
            this.alertShow = true;
            this.alertText = alertText;
        },
        // 关闭警告框
        closeTip () {
            this.alertShow = false;
            this.alertText = "";
        },
        // 获取图片验证码
        getCaptcha () {
            const {phone} = this;
            if(!phone){
                this.showAlert("请输入支付宝绑定的手机号");
                return;
            }else if(!this.rightPhone){
                this.showAlert("请输入合法的手机号");
                return;
            }
            this.codeUrl = `${getPhoneImg()}?phone=${phone}&num=${Math.random()}`;
        },
        // 获取手机验证码
        async getSmsCode () {
            const {phone,captcha} = this;
            if(!phone){
                this.showAlert("请输入支付宝绑定的手机号");
                return;
            }else if(!this.rightPhone){
                this.showAlert("请输入合法的手机号");
                return;
            }else if(!captcha){
                this.showAlert("请输入图片验证码");
                return;
            }
            // 如果当前没有计时(避免正在倒计时重新发送验证码出现的问题)
            if(!this.computeTime){
                // 启动倒计时
                this.computeTime = 120;
                this.intervalId = setInterval(() => {
                    this.computeTime--;
                    if(this.computeTime<=0){
                        // 停止计时
                        clearInterval(this.intervalId);
                    }
                },1000)
            }
            // 发送ajax请求(向指定手机号发送验证码短信)
            const result = await sendSmsCode(phone,captcha); // 两种结果，成功或失败

            /*if(result.code==1){
                this.smsCode = "666666";
            }*/
            // 如果失败
            if(result.code!=1){
                // 显示提示
                this.showAlert(result.msg);
                // 停止倒计时
                if(this.computeTime){
                    this.computeTime = 0;
                    clearInterval(this.intervalId);
                    this.intervalId = undefined;
                }
            }
        },
        // 选择提现金额
        selectNum(index,num){
            for(let i in this.isChoose){
                // // 每次点击时：先让所有的 isChoose 为 false
                this.$set(this.isChoose,i,false);
            }
            // 设置当前点击（index）的选框的isChoose为true
            this.$set(this.isChoose,index,true);
            this.amount = num;
            console.log(this.amount)
        },
        // 提现功能
        async cashOut(){
            const {name,account,phone,captcha,amount,smsCode} = this;
            if(!name){
                // 用户名必须指定
                this.showAlert("请输入用户真实姓名");
                return;
            }else if(!account){
                this.showAlert("请输入支付宝账号");
                return;
            }else if(!phone){
                // 密码必须指定
                this.showAlert("请输入支付宝绑定的手机号");
                return;
            }else if(!captcha){
                // 验证码必须指定
                this.showAlert("请输入图片验证码");
                return;
            }else if(!smsCode){
                // 验证码必须指定
                this.showAlert("请输入手机验证码");
                return;
            }else if(!amount){
                this.showAlert("请选择提现金额");
                return;
            }

            const formData = new FormData();
            formData.append('name', name);
            formData.append('account', account);
            formData.append('phone', phone);
            formData.append('amount', amount);
            formData.append('captcha', smsCode);

            let result = await addCashOut(formData);
            if(result.code==1){
                Toast('提现申请成功');
                this.$router.push("/mywithdraw");
            }else {
                this.showAlert(result.msg);
            }
        },
    }
}
</script>

<style lang="less" scoped>
@import "../../common/stylus/mixins.less";
.cash {
    width: 100%;
    padding-bottom: 30px;
    .header {
        .header_search {
            .iconfont {
                color: #fff;
            }
        }
    }
    .cash-content {
        .clearFix();
        margin-top: 45px;
        .cash-out {
            .clearFix();
            margin: 10px auto 0;
            width: 96%;
            padding: 0px 10px 20px;
            background: #fff;
            box-sizing: border-box;
            color: #333;
            border-radius: 5px;
            .cash-amount {
                margin-top: 20px;
                line-height: 30px;
                span:nth-child(2){
                    margin-left: 30px;
                    font-size: 24px;
                    font-weight: 700;
                }
            }
            .cash-info {
                /*width: 92%;
                margin: 0 auto;*/
                >form {
                    >div {
                        input {
                            width: 100%;
                            height: 100%;
                            padding-left: 10px;
                            box-sizing: border-box;
                            border: 1px solid #ddd;
                            border-radius: 4px;
                            outline: 0;
                            font: 400 14px Arial;
                            &:focus {
                                border: 1px solid #02a774;
                            }
                        }
                        .cash_message {
                            position: relative;
                            margin-top: 16px;
                            height: 48px;
                            font-size: 14px;
                            background: #fff;
                            .get_verification {
                                position: absolute;
                                top: 50%;
                                right: 10px;
                                transform: translateY(-50%);
                                color: #666;
                                font-size: 14px;
                                border: 0;
                                background: transparent;
                                &.right_phone {
                                    color: #333;
                                }
                            }
                        }
                        .cash_option {
                            font-size: 14px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            .cash-type {
                                margin-top: 16px;
                                display: inline-block;
                                width: 31%;
                                height: 48px;
                                line-height: 48px;
                                text-align: center;
                                background: #fff;
                                border: 1px solid #ddd;
                                border-radius: 4px;
                                &.link {
                                    border-color: #02a774;
                                    color: #02a774;
                                    position: relative;
                                    &:before {
                                        content: "";
                                        position: absolute;
                                        right: 0;
                                        bottom: 0;
                                        border: 12px solid #02a774;
                                        border-top-color: transparent;
                                        border-left-color: transparent;
                                    }
                                    &:after {
                                        content: "";
                                        width: 5px;
                                        height: 10px;
                                        position: absolute;
                                        right: 4px;
                                        bottom: 5px;
                                        border: 1px solid #fff;
                                        border-top-color: transparent;
                                        border-left-color: transparent;
                                        transform: rotate(45deg);
                                    }
                                }
                            }
                        }
                    }
                    .cash-btn {
                        display: block;
                        width: 100%;
                        height: 42px;
                        background: #02a774;
                        border-radius: 4px;
                        margin-top: 30px;
                        font-size: 16px;
                        line-height: 42px;
                        text-align: center;
                        color: #fff;
                        border: 0;
                    }
                }
            }
        }
    }
}
</style>