import Vue  from 'vue';
import VueRouter from 'vue-router';

import {localCache} from "@/utils/utils";

const Msite = () => import('../pages/Msite/Msite.vue');
const QA = () => import('../pages/QA/QA.vue');
const Payment = () => import('../pages/Payment/Payment.vue');
const Recharge = () => import('../pages/Recharge/Recharge.vue');
const Profile = () => import('../pages/Profile/Profile.vue');

import Login from "@/pages/Login/Login.vue";
import Register from "@/pages/Register/Register.vue";
import ForgetPwd from "@/pages/ForgetPwd/ForgetPwd.vue";
import MyOrder from "@/pages/MyOrder/MyOrder.vue";
import MyRebate from "@/pages/MyRebate/MyRebate.vue";
import Cash from "@/pages/Cash/Cash.vue";
import EditProfile from "@/pages/EditProfile/EditProfile.vue";
import ContactUs from "@/pages/ContactUs/ContactUs.vue";
import MyWithdraw from "@/pages/MyWithdraw/MyWithdraw.vue";
import AddContact from "@/pages/AddContact/AddContact.vue";
import ContactDetail from "@/pages/ContactDetail/ContactDetail.vue";

// 声明使用插件
Vue.use(VueRouter);
// 向外暴露路由器对象
const router =  new VueRouter({
    // 配置n个路由
    routes: [
        {
            path: "/msite",
            /* 返回路由组件的函数, 只有执行此函数才会加载路由组件,
             这个函数在请求对应的路由路径时才会执行 */
            component: Msite,
            meta: {
                showFooter: true
            }
        },
        {
            path: "/qa",
            component: QA,
            meta: {
                showFooter: true
            }
        },
        {
            path: "/payment",
            component: Payment,
            meta: {
                showFooter: true
            }
        },
        {
            path: "/recharge",
            component: Recharge,
            meta: {
                showFooter: true
            }
        },
        {
            path: "/profile",
            component: Profile,
            meta: {
                showFooter: true
            }
        },
        {   // 自动跳转路由
            path: "/",
            redirect: "/msite"
        },
        {
            path: "/login",
            component: Login,
        },
        {
            path: "/register",
            component: Register,
        },
        {
            path: "/forgetpwd",
            component: ForgetPwd,
        },
        {
            path: "/myorder",
            component: MyOrder,
        },
        {
            path: "/myrebate",
            component: MyRebate,
        },
        {
            path: "/cash",
            component: Cash,
        },
        {
            path: "/mywithdraw",
            component: MyWithdraw,
        },
        {
            path: "/editprofile",
            component: EditProfile,
        },
        {
            path: "/contactus",
            component: ContactUs,
        },
        {
            path: "/addcontact",
            component: AddContact,
        },
        {
            path: "/contactdetail",
            component: ContactDetail,
        },
    ]
})


// 以下是解决：Vue重复点击相同路由造成控制台报错
/*
* vue-router.esm.js:2065 Uncaught (in promise) NavigationDuplicated:
* Avoided redundant navigation to current location: "/msite".
* */
// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
    // to 将要访问的路径
    // from 代表从哪个路径跳转而来
    // next 是一个函数，表示放行
    //     next()  放行    next('/login')  强制跳转

    if (to.path === '/login') return next();
    // if(to.path === '/msite') return next();
    if(to.path === '/register') return next();
    if(to.path === '/forgetpwd') return next();
    // 获取token
    const tokenStr = localCache.get("token");
    if (!tokenStr) return next('/login');
    next();
})


export default router;