<template>
    <div id="app">
        <router-view></router-view>
        <FooterGuide v-show="$route.meta.showFooter"/>
    </div>
</template>

<script>
import FooterGuide from './components/FooterGuide/FooterGuide';

export default {
    name: 'App',
    components: {
        FooterGuide
    },
}
</script>

<style lang="less">
    #app {
        width: 100%;
        height: 100%;
        background: #ededed;
        // position: relative;
    }
</style>
