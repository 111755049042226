<template>
    <section class="loginContainer">
        <div class="loginInner">
            <div class="login_header">
                <h2 class="login_logo">农技百科</h2>
            </div>
            <div class="login_content">
                <form @submit.prevent="login">
                    <div>
                        <section>
                            <section class="login_message">
                                <input type="tel" placeholder="手机号" v-model="phone" autoComplete="off" @blur="getCaptcha" @input="limitLength">
                            </section>
                            <input type="text" value="" style=" opacity: 0; width: 0;height: 0; " />
                            <section class="login_verification">
                                <!-- 密码切换：
                                1. 需两个input，一个type为text，一个type为password，text时密码显示，pwd时密码隐藏，两个input需切换显示（v-if判断）
                                2. 输入文本，点击按钮切换 -->
                                <input type="text" minlength="6" maxlength="20" placeholder="密码" autoComplete="new-password" v-if="showPwd" v-model="password">
                                <input type="password" minlength="6" maxlength="20" placeholder="密码" autoComplete="new-password" v-else v-model="password">
                                <!-- 点击切换按钮（全部用三目运算符判断）
                                1. switch_button类名：on  off切换
                                2. switch_circle类名：right有  没有
                                3. switch_text类名：abc内容有   没有
                                right加样式：transform:translateX(27px) -->
                                <div class="switch_button" :class="showPwd?'on':'off'" @click="showPwd=!showPwd">
                                    <!-- 密码显示时才有小圆圈 -->
                                    <div class="switch_circle" :class="{right: showPwd}"></div>
                                    <!-- 密码显示时用abc -->
                                    <span class="switch_text">{{showPwd ? 'abc' : '...'}}</span>
                                </div>
                            </section>
                            <section class="login_message">
                                <input type="text" maxlength="4" placeholder="验证码" v-model="captcha">
                                <span class="get_verification" v-if="!codeUrl" @click="getCaptcha">获取图片验证码</span>
                                <img v-else class="get_verification" :src="codeUrl" alt="captcha" @click="getCaptcha" ref="captcha"/>
                            </section>
                        </section>
                    </div>
                    <router-link class="foget_pwd" to="/forgetpwd">
                        忘记密码？
                    </router-link>
                    <button class="login_submit">登录</button>
                </form>
                <div class="register">
                    <span @click="toRegister">还没有账号？<span class="span_bg">点击注册</span></span>
                </div>
            </div>
<!--            <a href="javascript:" class="go_back" @click="$router.back()">
                <i class="iconfont icon-fanhui"></i>
            </a>-->
        </div>
        <AlertTip :alertText="alertText" v-show="alertShow" @closeTip="closeTip"></AlertTip>
    </section>
</template>

<script>
import AlertTip from "@/components/AlertTip/AlertTip";
import {getCodeImg,reqPwdLogin} from "@/api/index.js";
import {localCache} from "@/utils/utils";

export default {
    name: "Login",
    data () {
       return {
           phone: '', // 手机号
           password: '', // 密码
           captcha: '', // 图形验证码
           platformCode: 1191,
           codeUrl: "", // 图形验证码url
           showPwd: false, // 是否显示密码
           alertText: "", // 提示文本
           alertShow: false, // 是否显示警告框
       }
    },
    components: {
        AlertTip
    },
    computed: {
        rightPhone () {
            return /1[3456789]\d{9}/.test(this.phone);
        }
    },
    methods: {
        showAlert(alertText){
            this.alertShow = true;
            this.alertText = alertText;
        },
        // 关闭警告框
        closeTip () {
            this.alertShow = false;
            this.alertText = "";
        },
        // 限制输入长度
        limitLength() {
            if (this.phone.length > 11) {
                this.phone = this.phone.slice(0, 11)
            }
        },
        toRegister(){
            this.$router.push("/register");
        },
        // 获取图片验证码
        getCaptcha () {
            const {phone,platformCode} = this;
            if(!phone){
                this.showAlert("请输入手机号");
                return;
            }else if(!this.rightPhone){
                this.showAlert("请输入合法的手机号");
                return;
            }
            this.codeUrl = `${getCodeImg()}?userName=${phone}&platformCode=${platformCode}&num=${Math.random()}`;
        },
        // 登录
        async login() {
            const {phone, password, captcha,platformCode} = this;
            if(!phone){
                // 用户名必须指定
                this.showAlert("请输入手机号");
                return;
            }else if(!this.rightPhone){
                this.showAlert("请输入合法的手机号");
                return;
            } else if(!password){
                // 密码必须指定
                this.showAlert("请输入密码");
                return;
            }else if(!captcha){
                // 验证码必须指定
                this.showAlert("请输入验证码");
                return;
            }

            let result = await reqPwdLogin(phone, password, captcha,platformCode);
            // 根据结果数据处理
            if(result.code == 1){
                // 成功
                const user = result.data;
                // 1. 将user信息保存到vuex的state
                // this.$store.dispatch("userAbout/recordUser",user);
                // 将token存入本地
                console.log(result.data);
                localCache.set("token",result.data);
                // 2. 要跳转去个人中心页面
                this.$router.replace("/msite");
            }else{// 失败
                // 显示新的图片验证码
                this.getCaptcha();
                // 显示警告提示
                const msg = result.msg;
                this.showAlert(msg);
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../common/stylus/mixins.less";
.loginContainer {
    width: 100%;
    height: 100%;
    background: #fff;
    .loginInner {
        padding-top: 60px;
        width: 80%;
        margin: 0 auto;
        .login_header {
            .login_logo {
                font-size: 40px;
                font-weight: bold;
                color: #02a774;
                text-align: center;
            }
        }
        .login_content {
            margin-top: 40px;
            >form {
                >div {
                    input {
                        width: 100%;
                        height: 100%;
                        padding-left: 10px;
                        box-sizing: border-box;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        outline: 0;
                        font: 400 14px Arial;
                        &:focus {
                            border: 1px solid #02a774;
                        }
                    }
                    .login_message {
                        position: relative;
                        margin-top: 16px;
                        height: 48px;
                        font-size: 14px;
                        background: #fff;
                        .get_verification {
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);
                            color: #666;
                            font-size: 14px;
                            border: 0;
                            background: transparent;
                        }
                    }
                    .login_verification {
                        position: relative;
                        //margin-top: 16px;
                        height: 48px;
                        font-size: 14px;
                        background: #fff;
                        .switch_button {
                            width: 30px;
                            height: 16px;
                            line-height: 16px;
                            border: 1px solid #ddd;
                            border-radius: 8px;

                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);

                            font-size: 12px;
                            color: #fff;
                            transition: background-color .3s,border-color .3s;
                            padding: 0 6px;

                            &.on {
                                background: #02a774;
                            }
                            &.off {
                                background: #fff;
                                .switch_text {
                                    float: right;
                                    color: #ddd;
                                }
                            }

                            .switch_circle {
                                position: absolute;
                                top: -1px;
                                left: -1px;
                                width: 16px;
                                height: 16px;
                                border: 1px solid #ddd;
                                border-radius: 50%;
                                background: #fff;
                                box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
                                transition: transform .3s;
                                &.right {
                                    transform: translateX(30px);
                                }
                            }
                        }
                    }
                }
                .foget_pwd {
                    display: block;
                    font-size: 14px;
                    margin-top: 10px;
                    color: #999;
                    float: right;
                    padding-bottom: 30px;
                }
                .login_submit {
                    display: block;
                    width: 100%;
                    height: 42px;
                    background: #02a774;
                    border-radius: 4px;
                    font-size: 16px;
                    line-height: 42px;
                    text-align: center;
                    color: #fff;
                    border: 0;
                }
            }
            .register {
                display: block;
                text-align: center;
                margin-top: 15px;
                font-size: 14px;
                color: #999;
                span.span_bg {
                    color: #02a774;
                }
            }
        }
        .go_back {
            position: absolute;
            top: 5px;
            left: 5px;
            width: 30px;
            height: 30px;
            >.iconfont {
                font-size: 20px;
                color: #999;
            }

        }
    }
}
</style>