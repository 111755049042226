<template>
    <section class="contact-detail">
        <HeaderTop title="详情">
            <router-link class="header_search" slot="left" to="/contactus">
                <i class="iconfont icon-fanhui"></i>
            </router-link>
        </HeaderTop>
        <section class="detail-content">
            <section class="detail" v-for="(reply,index) in detail.reply" :key="reply.id">
                <p>
                    <span class="title">{{reply.title}}</span>
                </p>
                <p class="text">
                    {{reply.content}}
                </p>
                <p>
                    <span>回复时间：{{reply.replyDate}}</span>
                </p>
            </section>
            <section class="detail">
                <p>
                    <span class="title">{{detail.title}}</span>
                </p>
                <p class="text">
                    {{detail.content}}
                </p>
                <p>
                    <span>创建时间：{{detail.createDate}}</span>
                </p>
            </section>
        </section>
    </section>
</template>

<script>
import HeaderTop from "@/components/HeaderTop/HeaderTop";
export default {
    name: "ContactDetail",
    data(){
        return {
            detail: {}, // 详情
        }
    },
    mounted() {
        let obj = this.$route.query.detail;
        this.detail = JSON.parse(obj);
        console.log(this.detail);
    },
    components: {
        HeaderTop
    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
@import "../../common/stylus/mixins.less";
.contact-detail {
    width: 100%;
    .header {
        .header_search {
            .iconfont {
                color: #fff;
            }
        }
    }
    .detail-content {
        .clearFix();
        margin-top: 45px;
        .detail {
            margin: 10px auto 0;
            width: 96%;
            padding: 15px 10px;
            background: #fff;
            box-sizing: border-box;
            color: #333;
            border-radius: 5px;
            p {
                font-size: 15px;
                color: #333;
                line-height: 30px;
                span {

                    &.title {
                        font-weight: bold;
                    }
                }
            }
            .text {
                word-wrap: break-word;
                word-break: break-all;
                line-height: 21px;
                padding: 5px 0;
            }
        }
    }
}
</style>