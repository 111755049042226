import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import store from './store'
// import "./utils/setViewport"





import {Button,Loadmore} from 'mint-ui'
// 注册全局组件
Vue.component(Button.name,Button) // <mt-button>
Vue.component(Loadmore.name, Loadmore);

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router, // 使用上vue-router
    store, // 使用上store
}).$mount('#app')
