<template>
    <footer class="footer_guide border-1px">
        <a href="javascript:;" class="guide_item" :class="{on: '/msite'===$route.path}"  @click="goTo('/msite')">
            <span class="item_icon">
                <i class="iconfont icon-shouye"></i>
            </span>
            <span>首页</span>
        </a>
        <a href="javascript:;" class="guide_item" :class="{on: '/qa'===$route.path}" @click="goTo('/qa')">
            <span class="item_icon">
                <i class="iconfont icon-wenda"></i>
            </span>
            <span>农技问答</span>
        </a>
        <a href="javascript:;" class="guide_item" :class="{on: '/payment'===$route.path}" @click="goTo('/payment')">
            <span class="item_icon">
                <i class="iconfont icon-chongzhi"></i>
            </span>
            <span>点数充值</span>
        </a>
        <a href="javascript:;" class="guide_item" :class="{on: '/recharge'===$route.path}" @click="goTo('/recharge')">
            <span class="item_icon">
                <i class="iconfont icon-taocanyuliang"></i>
            </span>
            <span>我的套餐</span>
        </a>
        <a href="javascript:;" class="guide_item" :class="{on: '/profile'===$route.path}" @click="goTo('/profile')">
            <span class="item_icon">
                <i class="iconfont icon-wode"></i>
            </span>
            <span>个人中心</span>
        </a>
    </footer>
</template>

<script>
    export default {
        mounted() {
            this.$router.push("/msite");
        },
        methods: {
            goTo(path){
                // history.pushState({name: path}, null,path);
                this.$router.push(path);
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../common/stylus/mixins.less";
    .footer_guide{
        .top-border-1px(#e4e4e4);
        position: fixed;
        z-index: 100;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        width: 100%;
        //padding-bottom: calc(50px + env(safe-area-inset-bottom));
        height: 50px;
        display: flex;
        .guide_item {
            display: flex;
            flex: 1; // 子元素等分空间
            text-align: center;
            flex-direction: column;
            align-items: center;
            margin: 5px;
            color: #999999;
            &.on {
                color: #02a774;
            }
            span {
                font-size: 12px;
                margin-top: 2px;
                margin-bottom: 2px;
                .iconfont {
                    font-size: 22px;
                }
            }
        }
    }

</style>
