<template>
    <section class="edit">
        <HeaderTop title="修改资料">
            <router-link class="header_search" slot="left" :to="navName?'/msite':'/profile'">
                <i class="iconfont icon-fanhui"></i>
            </router-link>
        </HeaderTop>
        <section class="edit-content">
            <div class="edit-info">
                <form @submit.prevent="update">
                    <div>
                        <section>
                            <section class="edit_message">
                                <input type="text" placeholder="请输入用户名称" v-model="realName">
                            </section>
                            <section class="edit_message">
                                <input type="text" placeholder="请输入企业名称" v-model="companyName">
                            </section>
                            <section class="edit_verification">
                                <input type="text" minlength="6" maxlength="20" placeholder="请输入原密码" v-if="showPwd1" v-model="pwd1">
                                <input type="password" minlength="6" maxlength="20" placeholder="请输入原密码" v-else v-model="pwd1">
                                <div class="switch_button" :class="showPwd1?'on':'off'" @click="showPwd1=!showPwd1">
                                    <div class="switch_circle" :class="{right: showPwd1}"></div>
                                    <span class="switch_text">{{showPwd1 ? 'abc' : '...'}}</span>
                                </div>
                            </section>
                            <section class="edit_verification">
                                <input type="text" minlength="6" maxlength="20" placeholder="请输入新密码" v-if="showPwd2" v-model="pwd2">
                                <input type="password" minlength="6" maxlength="20" placeholder="请输入新密码" v-else v-model="pwd2">
                                <div class="switch_button" :class="showPwd2?'on':'off'" @click="showPwd2=!showPwd2">
                                    <div class="switch_circle" :class="{right: showPwd2}"></div>
                                    <span class="switch_text">{{showPwd2 ? 'abc' : '...'}}</span>
                                </div>
                            </section>
                            <section class="edit_verification">
                                <input type="text" minlength="6" maxlength="20" placeholder="再次输入新密码" v-if="showPwd3" v-model="pwd3">
                                <input type="password" minlength="6" maxlength="20" placeholder="再次输入新密码" v-else v-model="pwd3">
                                <div class="switch_button" :class="showPwd3?'on':'off'" @click="showPwd3=!showPwd3">
                                    <div class="switch_circle" :class="{right: showPwd3}"></div>
                                    <span class="switch_text">{{showPwd3 ? 'abc' : '...'}}</span>
                                </div>
                            </section>
                            <section class="edit_message">
                                <input type="text" maxlength="4" placeholder="请输入图片验证码" v-model="captcha">
                                <span class="get_verification" v-if="!codeUrl" @click="getCaptcha">获取图片验证码</span>
                                <img v-else class="get_verification" :src="codeUrl" alt="captcha" @click="getCaptcha" ref="captcha"/>
                            </section>
                        </section>
                    </div>
                    <button class="edit-btn">确认</button>
                </form>
            </div>
        </section>
        <AlertTip :alertText="alertText" v-show="alertShow" @closeTip="closeTip"></AlertTip>
    </section>
</template>

<script>
import HeaderTop from "@/components/HeaderTop/HeaderTop";
import AlertTip from "@/components/AlertTip/AlertTip";
import {updateVerify,updateUser} from "@/api";
import { Toast } from 'mint-ui';
export default {
    name: "EditProfile",
    components: {
        HeaderTop,
        AlertTip,
    },
    data () {
        return {
            navName: "", // 携带导航名(以便区分返回到首页还是个人中心页)
            realName: "", // 用户名称
            companyName: "", // 企业名称
            pwd1: "", // 原密码
            pwd2: "", // 新密码
            pwd3: "", // 确认新密码
            showPwd1: false, // 是否显示密码
            showPwd2: false, // 是否显示密码
            showPwd3: false, // 是否显示密码
            captcha: "", // 图形验证码
            codeUrl: "", // 图形验证码url
            userId: "",  // 用户ID
            platformCode: 1191,
            alertText: "", // 提示文本
            alertShow: false, // 是否显示警告框
        }
    },
    mounted() {
        this.navName = this.$route.query.name;
        this.userId = this.$route.query.id;
    },
    methods: {
        showAlert(alertText){
            this.alertShow = true;
            this.alertText = alertText;
        },
        // 关闭警告框
        closeTip () {
            this.alertShow = false;
            this.alertText = "";
        },
        // 获取修改资料验证图片
        getCaptcha () {
            this.codeUrl = `${updateVerify()}?userId=${this.userId}&num=${Math.random()}`;
        },
        // 用户修改资料
        async update(){
            const {realName,companyName,pwd1,pwd2,pwd3,captcha} = this;
            if(!captcha){
                // 验证码必须指定
                this.showAlert("请输入图片验证码");
                return;
            }
            if(pwd1 || pwd2 || pwd3 ){
                if(!pwd1){
                    this.showAlert("请输入原密码");
                    return;;
                }
                if(!pwd2){
                    this.showAlert("请输入新密码");
                    return;;
                }
                if(!pwd3){
                    this.showAlert("再次输入新密码");
                    return;;
                }
            }

            const formData = new FormData();
            formData.append('realName', realName);
            formData.append('companyName', companyName);
            formData.append('oldPwd', pwd1);
            formData.append('newPwd', pwd2);
            formData.append('verifyCode', captcha);

            let result = await updateUser(formData);
            if(result.code==1){
                Toast('修改资料成功');
                this.$router.push("/profile");
            }else {
                this.showAlert(result.msg);
            }
        }
    }
}
</script>

<style lang="less" scoped>
@import "../../common/stylus/mixins.less";
.edit {
    width: 100%;
    .header {
        .header_search {
            .iconfont {
                color: #fff;
            }
        }
    }
    .edit-content {
        margin-top: 45px;
        .clearFix();
        .edit-info {
            .clearFix();
            margin: 10px auto 0;
            width: 96%;
            padding: 4px 10px 20px;
            background: #fff;
            box-sizing: border-box;
            color: #333;
            border-radius: 5px;
            >form {
                >div{
                    input {
                        width: 100%;
                        height: 100%;
                        padding-left: 10px;
                        box-sizing: border-box;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        outline: 0;
                        font: 400 14px Arial;
                        &:focus {
                            border: 1px solid #02a774;
                        }
                    }
                    .edit_message {
                        position: relative;
                        margin-top: 16px;
                        height: 48px;
                        font-size: 14px;
                        background: #fff;
                        .get_verification {
                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);
                            color: #666;
                            font-size: 14px;
                            border: 0;
                            background: transparent;
                            &.right_phone {
                                color: #333;
                            }
                        }
                    }
                    .edit_verification {
                        position: relative;
                        margin-top: 16px;
                        height: 48px;
                        font-size: 14px;
                        background: #fff;
                        .switch_button {
                            width: 30px;
                            height: 16px;
                            line-height: 16px;
                            border: 1px solid #ddd;
                            border-radius: 8px;

                            position: absolute;
                            top: 50%;
                            right: 10px;
                            transform: translateY(-50%);

                            font-size: 12px;
                            color: #fff;
                            transition: background-color .3s,border-color .3s;
                            padding: 0 6px;

                            &.on {
                                background: #02a774;
                            }
                            &.off {
                                background: #fff;
                                .switch_text {
                                    float: right;
                                    color: #ddd;
                                }
                            }

                            .switch_circle {
                                position: absolute;
                                top: -1px;
                                left: -1px;
                                width: 16px;
                                height: 16px;
                                border: 1px solid #ddd;
                                border-radius: 50%;
                                background: #fff;
                                box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
                                transition: transform .3s;
                                &.right {
                                    transform: translateX(30px);
                                }
                            }
                        }
                    }
                }
                .edit-btn {
                    display: block;
                    width: 100%;
                    height: 42px;
                    background: #02a774;
                    border-radius: 4px;
                    margin-top: 30px;
                    font-size: 16px;
                    line-height: 42px;
                    text-align: center;
                    color: #fff;
                    border: 0;
                }
            }
        }
    }
}
</style>