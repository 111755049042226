<template>
    <section class="withdraw">
        <HeaderTop title="提现记录">
            <router-link class="header_search" slot="left" to="/cash">
                <i class="iconfont icon-fanhui"></i>
            </router-link>
        </HeaderTop>
        <section class="withdraw-content">
            <div class="records" v-for="(record,index) in records" :key="record.id">
                <p>
                    <span class="num">支付宝账号：{{record.account}}</span>
                    <span class="sum">{{record.amount}}元</span>
                </p>
                <p>
                    <span>申请提现时间：</span>
                    <span>{{record.applyDate}}</span>
                </p>
                <p>
                    <span>提现到账时间：</span>
                    <span>{{record.arrivalDate}}</span>
                </p>
                <p>
                    <span>提现状态：</span>
                    <span>{{record.statusName}}</span>
                </p>
            </div>
        </section>
    </section>
</template>

<script>
import HeaderTop from "@/components/HeaderTop/HeaderTop";
import {queryWithdraw} from "@/api";
export default {
    name: "MyWithdraw",
    data(){
        return {
            currentPage: 1, // 查询页数
            rowCount: 10, // 每页行数
            records: [], // 提现记录
        }
    },
    components: {
        HeaderTop,
    },
    mounted() {
        this.getWithdraw();
    },
    methods: {
        // 查询提现记录
        async getWithdraw(){
            let result = await queryWithdraw(this.currentPage,this.rowCount);
            if(result.code==1){
                this.records = result.data.data;
                // console.log(this.queryWithdraw);
            }
        },
    }
}
</script>

<style lang="less" scoped>
@import "../../common/stylus/mixins.less";
.withdraw {
    width: 100%;
    padding-bottom: 30px;
    .header {
        .header_search {
            .iconfont {
                color: #fff;
            }
        }
    }
    .withdraw-content {
        .clearFix();
        margin-top: 45px;
        .records {
            margin: 10px auto 0;
            width: 96%;
            padding: 15px 10px;
            background: #fff;
            box-sizing: border-box;
            color: #333;
            border-radius: 5px;
            p {
                font-size: 15px;
                color: #333;
                line-height: 30px;
                display: flex;
                justify-content: space-between;
                span {
                    &.num {
                        font-weight: bold;
                    }
                    &.sum {
                        color: red;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
</style>