// vuex最核心的管理对象store
import Vue from 'vue';
import Vuex from "vuex";
import userData from "./user";
import comboData from "./recharge";

// 应用Vuex插件
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        userAbout: userData,
        comboAbout: comboData,
    },
    /*state: {
        // 存储token
        Authorization: sessionStorage.getItem('Authorization') ? sessionStorage.getItem('Authorization') : ''
    },
    mutations: {
        // 修改token，并将token存入localStorage
        changeLogin (state, user) {
            state.Authorization = user.Authorization;
            sessionStorage.setItem('Authorization', user.Authorization);
        }
    }*/
})
