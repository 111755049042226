<template>
    <section class="my-order">
        <HeaderTop title="我的订单">
            <router-link class="header_search" slot="left" :to="navName?'/msite':'/profile'">
                <i class="iconfont icon-fanhui"></i>
            </router-link>
        </HeaderTop>
        <section class="my-order-content" id="myOrder" >
            <section ref="order" class="order" v-for="(order,index) in orders" :key="order.id">
                <p>
                    <span class="num">订单编号：{{order.orderNo}}</span>
                    <button v-show="order.status==0" @click="toPay(order.orderNo)">去支付</button>
                </p>
                <p>
                    <span>套餐名称：{{order.paymentName}}</span>
                    <span>套餐价格：{{order.discountPrice}}元</span>
                </p>
                <p>
                    <span>支付方式：{{order.modeName}}</span>
                    <span>订单状态：
                        <span :class="{
                            'redColor':order.statusName=='待支付',
                            'greenColor':order.statusName=='已支付',
                        }">{{order.statusName}}</span>
                    </span>
                </p>
                <p>
                    <span>下单时间：</span>
                    <span>{{order.createDate}}</span>
                </p>
                <p>
                    <span>支付时间：</span>
                    <span>{{order.payDate}}</span>
                </p>
            </section>
        </section>
        <section class="my-order-qrcode" v-show="show">
            <div class="qrcode-bg">
                <div class="qrcode-pay">
                    <span class="text">请在手机微信上按住图片识别二维码支付</span>
                    <span class="icon" @click="close">
                        <i class="iconfont icon-guanbi"></i>
                    </span>
                </div>
                <div id="qrcode" class="qrcode">

                </div>
                <button class="qrcode-btn" @click="paySuccess">支付成功</button>
            </div>
        </section>
    </section>
</template>

<script>
import HeaderTop from "@/components/HeaderTop/HeaderTop";
import {queryOrder,goPay} from "@/api";
import throttle from "lodash/throttle";
import {Indicator,Toast} from "mint-ui";
export default {
    name: "MyOrder",
    data(){
        return{
            navName: "", // 携带导航名(以便区分返回到首页还是个人中心页)
            currentPage: 1, // 查询页数
            rowCount: 5, // 每页行数
            orders: [], // 订单
            orderNo: "", // 订单编号
            show: false, // 控制遮罩的显示与隐藏
            total: "", // 总条数
            messageContent: [],
        }
    },
    components: {
        HeaderTop
    },
    mounted() {
        this.navName = this.$route.query.name;
        this.getOrder();
        window.addEventListener("scroll", this.handleScroll, true);
    },
    methods: {
        // 查询Chat公用订单
        async getOrder(){
            let result = await queryOrder(this.currentPage,this.rowCount);
            /*if(result.code==1){
                let totalPage = this.rowCount; //规定每页的条数
                let data = result.data.data;
                this.total = data.length;
                if (this.total <= totalPage) { //如果说 后端返回的数据 少于或者等于 每页规定的数量，那就不需要做分页处理了
                    this.orders = data
                } else {
                    this.messageContent = data; //如查后端返回的数据超过每页规定的灵敏据，那就需要做分页
                    let arr = [];
                    for (var i = 0; i < totalPage; i++) {
                        arr.push(data[i]);
                    }
                    this.orders = arr
                }

            }*/
            if(result.code==1){
                this.orders = result.data.data;
                this.total = result.data.totalPage;
                console.log(this.orders);
            }/*else {
                this.messageContent = result.data.data; //如查后端返回的数据超过每页规定的灵敏据，那就需要做分页
                let arr = [];
                for (var i = 0; i < this.rowCount; i++) {
                    arr.push(result.data.data[i]);
                }
                this.orders = arr
            }*/
        },
        handleScroll(){
            // 这儿得出来的结果就是一共有多少页
            let totalPageSum = Math.ceil((this.total) / (this.rowCount));
            // 滚动条高度
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            // 屏幕可视区域高度
            const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            // 浏览器所有内容高度
            const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            // 根据项目实际定义
            const height = 10;
            // 判断是否触底了
            // if(scrollTop +clientHeight > scrollHeight - height){
            if(scrollTop == scrollHeight - clientHeight){
                // 如果当前页数小于或者等于总页数
                if ( this.currentPage <= totalPageSum) {
                    Indicator.open({
                        text: '加载中',
                        spinnerType: 'fading-circle'
                    });
                    // 给个定时器，让它 500 毫妙之后 对数据做处理
                    setTimeout(async () => {
                        // 需要把当前的页数加上1，因为每一次滚动到底的时候，已经加载了一页了。所以，当前页也需要对应的加上1
                        this.currentPage = this.currentPage + 1;
                        let result = await queryOrder(this.currentPage,this.rowCount);
                        if(result.code==1){
                            let arr = result.data.data;
                            console.log(arr);
                            this.orders = [...this.orders,...arr];
                            console.log(this.orders);
                        }
                        Indicator.close();
                    }, 300)
                }else {
                    Toast("没有更多了");
                }
            }
        },
        // 去支付
        /*toPay: throttle(async function(orderNo){
            this.orderNo = orderNo;
            let result = await goPay(this.orderNo);
            if(result.code==1) {
                this.show = true;
                // 当遮罩层（弹层）出现的时候给body设置style:overflow:hidden;属性使遮罩层后的滚动条不能滚动
                document.querySelector('body').style.overflow = "hidden";

                let url = result.data.url;
                console.log(url);

                // 先根据链接绘制canvas
                var codeFigure = new AraleQRCode({
                    "text": url, // 需要生成二维码的链接
                    "size": 200
                });
                console.log(codeFigure); // codeFigure是一个canvas

                var image = new Image(); // 实例一个img
                // 使用canvas调用toDataURL()的方法转换成base64格式路径的png图片
                image.src = codeFigure.toDataURL("image/png");
                image.style.width = "100%"; // 设置样式

                var qrcode = document.querySelector('#qrcode');
                // 注意：不能将图片追加到canvas中，这样的话图片长按仍无法识别二维码
                // 因为图片追加到canvas中，还是canvas标签
                // codeFigure.appendChild(image);

                // 将图片追加到容器中，这样就可以识别图片
                qrcode.appendChild(image);
            }
        },2000),*/
        async toPay(orderNo){
            this.orderNo = orderNo;
            let result = await goPay(this.orderNo);
            if(result.code==1){
                this.show = true;
                // this.isBlock = "show-qrcode";
                // 当遮罩层（弹层）出现的时候给body设置style:overflow:hidden;属性使遮罩层后的滚动条不能滚动
                document.querySelector('body').style.overflow="hidden";

                let url = result.data.url;
                console.log(url);

                // 先根据链接绘制canvas
                var codeFigure = new AraleQRCode({
                    "text": url, // 需要生成二维码的链接
                    "size": 200
                });
                console.log(codeFigure); // codeFigure是一个canvas

                var image = new Image(); // 实例一个img
                // 使用canvas调用toDataURL()的方法转换成base64格式路径的png图片
                image.src = codeFigure.toDataURL("image/png");
                image.style.width = "100%"; // 设置样式

                var qrcode = document.querySelector('#qrcode');
                // 注意：不能将图片追加到canvas中，这样的话图片长按仍无法识别二维码
                // 因为图片追加到canvas中，还是canvas标签
                // codeFigure.appendChild(image);

                // 将图片追加到容器中，这样就可以识别图片
                qrcode.appendChild(image);

            }

        },
        // 遮罩层的关闭按钮
        close () {
            this.show = false;
            document.querySelector('#qrcode').innerHTML = "";
            // 当遮罩层（弹层）消失的时候把body属性设置成overflow：auto，页面就可以继续在刚浏览的位置浏览
            document.querySelector('body').style.overflow="auto";
        },
        // 支付成功
        paySuccess(){
            this.show = false;
            document.querySelector('#qrcode').innerHTML = "";
            this.currentPage = 1;
            this.getOrder();
            document.querySelector('body').style.overflow="auto";
        }
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll, true);
    }

}
</script>

<style lang="less" scoped>
@import "../../common/stylus/mixins.less";
.my-order {
    width: 100%;
    .header {
        .header_search {
            .iconfont {
                color: #fff;
            }
        }
    }
    .my-order-content {
        .clearFix();
        margin-top: 45px;
        overflow-y: scroll;
        .order {
            margin: 10px auto 0;
            width: 96%;
            padding: 15px 10px;
            background: #fff;
            box-sizing: border-box;
            color: #333;
            border-radius: 5px;
            p {
                font-size: 15px;
                color: #333;
                line-height: 30px;
                display: flex;
                justify-content: space-between;
                //align-items: center;
                span {
                    &.num {
                        font-weight: bold;
                    }
                }
                button {
                    background: #02a774;
                    font-size: 13px;
                    color: #fff;
                    border: 0;
                    outline: none;
                    border-radius: 5px;
                    padding: 10px;
                }
            }
        }
    }
    .my-order-qrcode {
        width: 100%;
        min-height: 100vh;
        background-color: rgba(0,0,0,.6);
        z-index: 999;
        // 不能使用position: absolute;否则遮罩层只在第一屏显示
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .qrcode-bg {
            width: 90%;
            height: 400px;
            background: #fff;
            border-radius: 5px;
            position: relative;
            .qrcode-pay {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 90%;
                height: 80px;
                padding: 0 20px;
                .text {
                    font-size: 15px;
                    color: #333;
                }
                .icon {
                    .iconfont {
                        font-size: 20px;
                    }
                }
            }
            .qrcode {
                width: 200px;
                height: 200px;
                z-index: 9999;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                margin-top: -10px;
            }
            .qrcode-btn {
                position: absolute;
                bottom: 30px;
                left: 50%;
                transform: translate(-50%, 0);
                background: #02a774;
                font-size: 15px;
                color: #fff;
                border: 0;
                outline: none;
                border-radius: 5px;
                padding: 10px 20px;
            }
        }
    }
}

.redColor {
    color: #ff4d4f;
}
.greenColor {
    color: #02a774;
}
</style>